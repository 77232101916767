import React, { useState, useEffect, useContext } from "react"
import { useTable, useSortBy } from "react-table"
import { COLORS } from "../../styles/colors"
import { AccountContext } from "../../auth/Account"

function Table({ columns, data, columnsToHide }) {

  const { getSession } = useContext(AccountContext)
  const [isLoggedInEmail, setIsLoggedInEmail ] = useState()

  useEffect(() => {
    getSession().then(({ email }) => {
      setIsLoggedInEmail(email)
    })
  }, [])

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columnsToHide,
      },
    },
    useSortBy
  )

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps([
                  {
                    style: { minWidth: column.minWidth, width: column.width, backgroundColor: COLORS.Secondary },
                  },
                  column.getSortByToggleProps(),
                ])}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render("Cell", {
                        firstName: row.original.firstName,
                        lastName: row.original.lastName,
                        phone: row.original.phone,
                        email: row.original.email,
                        type: row.original.type,
                        uuid: row.original.uuid,  
                        isLoggedIn: row.original.email === isLoggedInEmail ? true : false,
                        status: row.original.status
                    })}
                  </td>
                ) // YES
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default Table
