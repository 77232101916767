export const ValidatorStrings = (text) => {

  switch (text) {
    case "required":
      return "This field is required"
    case "maxLength":
      return "This field has hit its max character limit"
    case "minLength":
      return "This field requires at least 10 characters"
    case "pattern":
      return "Email must be valid"
    case "emailEqual":
      return "Passwords must match"
    case "validate":
      return "Invalid phone number format"
    case "externalIdInvalid": 
      return "Property ID is already in use";
    default:
      return "General Validation Error"
  }
}

export const ResetPasswordStrings = (text) => {

  switch (text) {
    case "required":
      return "This field is required"
    case "maxLength":
      return "This field has hit its max character limit"
    case "minLength":
      return "This field requires at least 10 characters"
    case "pattern":
      return "This field requires 6 numeric characters"
    case "emailEqual":
      return "Passwords must match"
    case "validate":
      return "Invalid phone number format"
    default:
      return "General Validation Error"
  }
}

export const WingnutValidatorStrings = (text) => {

  switch (text) {
    case "required":
      return "This field is required"
    case "maxLength":
      return "This field has hit its max character limit"
    case "minLength":
      return "This field requires at least 10 characters"
    case "pattern":
      return "Email must be valid"
    case "emailEqual":
      return "Passwords must match"
    case "validate":
      return "Invalid UUID format"
    default:
      return "General Validation Error"
  }
}


export const EmailValidatorStrings = (text) => {

  switch (text) {
    case "required":
      return "This field is required"
    case "maxLength":
      return "This field has hit its max character limit"
    case "minLength":
      return "This field requires at least 10 characters"
    case "pattern":
      return "Email must be valid"
    case "emailEqual":
      return "Emails must match"
    case "validate":
      return "Invalid phone number format"
    case "invite":
      return "This user already exists"
    default:
      return "General Validation Error"
  }
}

export const PhoneValidatorStrings = (text) => {

  switch (text) {
    case "required":
      return "This field is required"
    case "maxLength":
      return "This field has hit its max character limit"
    case "minLength":
      return "This field requires at least 10 characters"
    case "pattern":
      return "Phone number must not contain letters"
    case "emailEqual":
      return "Passwords must match"
    case "validate":
      return "Invalid phone number format"
    default:
      return "General Validation Error"
  }
}

export const HelpValidatorStrings = (text) => {

  switch (text) {
    case "required":
      return "This field is required"
    case "maxLength":
      return "This field has hit its 300 max character limit"
    case "minLength":
      return "This field requires at least 10 characters"
    case "pattern":
      return "Phone number must not contain letters"
    case "emailEqual":
      return "Passwords must match"
    case "validate":
      return "Invalid phone number format"
    default:
      return "General Validation Error"
  }
}

export const ServerStrings = (text) => {

  switch (text) {
    case "NotAuthorizedException":
      return "Incorrect email or password, please try again"
    case "LimitExceededException":
      return "Attempt limit exceeded, please try after some time"
    case "AliasExistsException":
      return "An account with the given email already exists"
    case "UserNotConfirmedException":
      return "Email is not confirmed"
    case "UsernameExistsException":
      return "An account with the given email already exists"
    case "InvalidParameterException":
      return "Invalid phone number format. Should be 123-123-1234"
    case "InvalidPasswordException":
      return "Password must be 8 characters, 1 number, 1 special character, 1 uppercase, 1 lowercase"
    case "ExpiredCodeException":
      return "Invalid code provided, please contact an admin"
    case "CodeMismatchException":
      return "Invalid code provided, please contact an admin"
    case "InvalidWingnut":
      return "Invalid wingnut ID provided, please check UUID"
    default:
      return "General Server Error"
  }
}
